let Environment = {
  nftPurchase: "0x1119c2d708E42Fb591FCe77283b0791a135855ba",
  chinaTomi: "0xaDF3202480bD62F3dB150E27A0F811f999F253B9",
  busd: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  tomi: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  instituson: "0xE2B4Ebbdd777A17DC5dD3C01142DaE95da05B6Cc",
  eth: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
  usdc: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  wbtc: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
  link: "0x514910771af9ca656af840dff83e8264ecf986ca",
  pepe: "0x6982508145454ce325ddbe47a25d4ec3d2311933",
  unisape: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
  gems: "0x3010ccb5419F1EF26D40a7cd3F0d707a0fa127Dc", //testnet
  GemsStaking: "0x6A1aa5ac35f38Bdb25BBD976E8eca942b23260b2", //TESTNET
  dop:'0x97a9a15168c22b3c137e6381037e1499c8ad0978',
};
export default Environment;