import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { InstitusionContract,NftPurchaseContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const InMarketCap = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.instituson;
  const contract = InstitusionContract(tokenAddress, web3);


  const inMarketCap = useCallback(
    async () => {
 
      try {
        const buy = await contract.methods.maxCap().call()
        return buy/10**18;
      } catch (error) {
        throw error;
      }
    },
    [contract, account,web3]
  );
  return { inMarketCap: inMarketCap };
};
export default InMarketCap;