import "./navbar.scss";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import Offcanvas from 'react-bootstrap/Offcanvas';


const Navbar = ({ show, setShow }) => {
  const { login, logout } = useAuth();
  const { account } = useWeb3React();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (setShow) {
      setShow(true);
    }
  };
  // const trustWallet = async () => {
  //   if (account) {
  //     await logout("walletconnect");
  //     localStorage.clear();
  //   } else {
  //     try {
  //       let a =  login("walletconnect");
  //       console.log("Aaaaaaa", a)
  //       handleClose()
  //       localStorage.setItem('connectorId', 'walletconnect');
  //       localStorage.setItem("flag", "true");
  //       return (a)
  //     } catch (err) {
  //       console.log("eerr", err)
  //     }
  //   }
  // };
  const trustWallet = async () => {
    if (account) {
      await logout("walletconnect");
      localStorage.clear();
    } else {
      try {
        login("walletconnect");
        handleClose();
        localStorage.setItem('connectorId', 'walletconnect');
        localStorage.setItem("flag", "true");
      } catch (err) {
        console.log("eerr", err);
      }
    }
  };

  const tomi = async () => {
    if (account) {
      await logout("walletconnect");
      localStorage.clear();
    } else {
      login("walletconnect");
      handleClose()
      localStorage.setItem('connectorId', 'walletconnect');
      localStorage.setItem("flag", "true");
    }
  };
  // console.log("acc", account)
  // const tomiPayWallet = async () => {
  //   if (account) {
  //     await logout("walletconnect1");
  //     localStorage.clear();
  //   } else {
  //     login("walletconnect1");
  //     handleClose()
  //     localStorage.setItem('connectorId', 'walletconnect');
  //     localStorage.setItem("flag", "true");
  //   }
  // };


  const connectMetaMask1 = async () => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      login("injected");
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      handleClose();


    }
  };

  const clearData = () => {
    localStorage.removeItem("connectorId");
    localStorage.removeItem("flag");
  };


  const [showw, setShoww] = useState(false);
  const handleClosew = () => setShoww(false);
  const handleShoww = () => setShoww(true);
  const TomiPayBol = localStorage.getItem('IsTomipay')
  const providerNull = localStorage.getItem('provider')
  // console.log("payyy", TomiPayBol)

  // for tomi variable

  //   useEffect(() => {
  //     // tomiPayWallet()
  //     // alert(TomiPayBol)

  // if(!account)

  //     {  // console.log("in hool",a)
  //       if (TomiPayBol == 'true') {
  //         // alert(`providerNull=${providerNull}`)
  //         trustWallet();
  //         // alert(`TomiPayBol=${TomiPayBol}`)
  //         if (providerNull=='true') {
  //           setShow(true)
  //         }
  //       }}


  //   }, [TomiPayBol,providerNull])



  return (
    <>
      <section className="main-navbar">
        <div className="custom-container">
          <nav class="navbar navbar-expand-lg p-0">
            <a class="navbar-brand" href="/">
              <img src="\logo.svg" alt="img" className="img-fluid" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              onClick={handleShoww}
            >
              <span class="navbar-toggler-icon">
                <img
                  src="\assets\navbar-bar.svg"
                  alt="img"
                  className="img-fluid"
                />
              </span>
            </button>
            <div
              class="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a href="/buy" class="nav-link active" aria-current="page">
                    Buy
                  </a>
                </li>
                <li class="nav-item">
                  <a href="/claim" class="nav-link">
                    Claim
                  </a>
                </li>
                <li class="nav-item">
                  <a href="/discount" class="nav-link">
                    Golden Discount <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                      <g clip-path="url(#clip0_5641_2127)">
                        <path d="M15.9986 8.49932C15.9986 7.36866 15.4253 6.37199 14.5533 5.78399C14.754 4.75199 14.4546 3.64199 13.6553 2.84199C12.856 2.04266 11.746 1.74332 10.7133 1.94399C10.1253 1.07199 9.12864 0.498657 7.99798 0.498657C6.86731 0.498657 5.87065 1.07199 5.28265 1.94399C4.25065 1.74332 3.13998 2.04266 2.34065 2.84199C1.54131 3.64132 1.24198 4.75132 1.44265 5.78399C0.570648 6.37199 -0.00268555 7.36866 -0.00268555 8.49932C-0.00268555 9.62999 0.570648 10.6267 1.44265 11.2147C1.24198 12.2467 1.54131 13.3573 2.34065 14.1567C3.13998 14.956 4.24998 15.2553 5.28265 15.0547C5.87065 15.9267 6.86731 16.5 7.99798 16.5C9.12864 16.5 10.1253 15.9267 10.7133 15.0547C11.7453 15.2553 12.856 14.956 13.6553 14.1567C14.4546 13.3573 14.754 12.2473 14.5533 11.2147C15.4253 10.6267 15.9986 9.62999 15.9986 8.49932ZM5.33198 6.49932C5.33198 6.13132 5.63065 5.83266 5.99865 5.83266C6.36664 5.83266 6.66531 6.13132 6.66531 6.49932C6.66531 6.86732 6.36664 7.16599 5.99865 7.16599C5.63065 7.16599 5.33198 6.86732 5.33198 6.49932ZM7.17598 11.166H5.62131L8.82131 5.83266H10.376L7.17598 11.166ZM9.99864 11.166C9.63064 11.166 9.33198 10.8673 9.33198 10.4993C9.33198 10.1313 9.63064 9.83265 9.99864 9.83265C10.3666 9.83265 10.6653 10.1313 10.6653 10.4993C10.6653 10.8673 10.3666 11.166 9.99864 11.166Z" fill="#FFC700" />
                      </g>
                      <defs>
                        <clipPath id="clip0_5641_2127">
                          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </li>
                {/* <li class="nav-item">
                  <a href="/ClaimNft" class="nav-link">
                    NFT
                  </a>
                </li> */}
              </ul>
              {/* <a href="https://dop.org/" className="connect-btn me-2">
                go to dop.org
              </a> */}
              {!account ? (
                <button className="connect-btn" onClick={handleShow}>
                  Connect Wallet
                </button>
              ) : (
                <button className="dissconnect-btn" onClick={connectMetaMask1}>
                  Disconnect
                </button>
              )}
            </div>
          </nav>
        </div >
      </section >

      <Modal
        className="connectwallet-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="twice-btns" >
            <button className="forhideee" onClick={connectMetaMask1}>
              <img src="\assets\metamask.svg" alt="img" className="img-fluid" />
              <h6>Metamask</h6>
            </button>
            {/* {TomiPayBol == 'true' ?
              <button onClick={trustWallet}>
                <img
                  src="\assets\tomiPAYLogo.png"
                  alt="img"
                  className="img-fluid payyy"
                />
                <h6>tomiPAY</h6>
              </button>
              : */}
            <button onClick={trustWallet}>
              <img
                src="\assets\walletconnect.svg"
                alt="img"
                className="img-fluid"
              />
              <h6>WalletConnect</h6>
            </button>

            {/* } */}

          </div>
        </Modal.Body>
      </Modal>

      <Offcanvas className="mobile-navbar" show={showw} onHide={handleClosew}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <img src="\logo.svg" alt="img" className="img-fluid" /> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <a href="/buy">Buy</a>
          <a href="/claim">Claim</a>
          <a href="/discount" class="nav-link">
            Golden Discount <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
              <g clip-path="url(#clip0_5641_2127)">
                <path d="M15.9986 8.49932C15.9986 7.36866 15.4253 6.37199 14.5533 5.78399C14.754 4.75199 14.4546 3.64199 13.6553 2.84199C12.856 2.04266 11.746 1.74332 10.7133 1.94399C10.1253 1.07199 9.12864 0.498657 7.99798 0.498657C6.86731 0.498657 5.87065 1.07199 5.28265 1.94399C4.25065 1.74332 3.13998 2.04266 2.34065 2.84199C1.54131 3.64132 1.24198 4.75132 1.44265 5.78399C0.570648 6.37199 -0.00268555 7.36866 -0.00268555 8.49932C-0.00268555 9.62999 0.570648 10.6267 1.44265 11.2147C1.24198 12.2467 1.54131 13.3573 2.34065 14.1567C3.13998 14.956 4.24998 15.2553 5.28265 15.0547C5.87065 15.9267 6.86731 16.5 7.99798 16.5C9.12864 16.5 10.1253 15.9267 10.7133 15.0547C11.7453 15.2553 12.856 14.956 13.6553 14.1567C14.4546 13.3573 14.754 12.2473 14.5533 11.2147C15.4253 10.6267 15.9986 9.62999 15.9986 8.49932ZM5.33198 6.49932C5.33198 6.13132 5.63065 5.83266 5.99865 5.83266C6.36664 5.83266 6.66531 6.13132 6.66531 6.49932C6.66531 6.86732 6.36664 7.16599 5.99865 7.16599C5.63065 7.16599 5.33198 6.86732 5.33198 6.49932ZM7.17598 11.166H5.62131L8.82131 5.83266H10.376L7.17598 11.166ZM9.99864 11.166C9.63064 11.166 9.33198 10.8673 9.33198 10.4993C9.33198 10.1313 9.63064 9.83265 9.99864 9.83265C10.3666 9.83265 10.6653 10.1313 10.6653 10.4993C10.6653 10.8673 10.3666 11.166 9.99864 11.166Z" fill="#FFC700" />
              </g>
              <defs>
                <clipPath id="clip0_5641_2127">
                  <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </a>
          {/* <a href="/ClaimNft">NFT</a> */}
          <div className="btnss">
            {/* {TomiPayBol ?
              '' : */}
            <>
              {!account ? (
                <button className="connect-btn" onClick={() => {
                  handleClosew();
                  handleShow();
                }}>
                  Connect Wallet
                </button>
              ) : (
                <button className="dissconnect-btn" onClick={connectMetaMask1}>
                  Disconnect
                </button>
              )}
            </>
            {/* } */}
            {/* <a href="https://dop.org/" className="connect-btn mt-2 w-100">
              go to dop.org
            </a> */}
          </div>

        </Offcanvas.Body>
        {/* <img
          src="\assets\mblviewbg.png"
          alt="img"
          className="img-fluid mblviewbanner-bg"
        /> */}
      </Offcanvas>
    </>
  );
};

export default Navbar;
