import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getGemsStakingContract } from "../../utils/contractHelpers";

export const GetGemsStakedIndex = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.GemsStaking;
  const contract = getGemsStakingContract(tokenAddress, web3);
  const getGemsStakedIndex = useCallback(
    async (account) => {
      const index = await contract.methods
        .stakeIndex(account?.toString())
        .call();
      return index;
    },
    [contract]
  );

  return { getGemsStakedIndex: getGemsStakedIndex };
};

export default GetGemsStakedIndex;