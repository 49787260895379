import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { usdtContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const AllowanceTpl = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const tokenAddress1 = Environment.busd;
  const contract = usdtContract(tokenAddress1, web3);


  const allowanceTpl = useCallback(
    async () => {
        // console.log(tokenAddress,account,'noor');
      try {
        const buy = await contract.methods.allowance(account,tokenAddress).call()
        // console.log('sdsdss' ,  buy) 
        return buy/10**6;
      } catch (error) {
        throw error;
      }
    },
    [contract, account]
  );
  return { allowanceTpl: allowanceTpl };
};
export default AllowanceTpl;