import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import {dopContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const BalacefAccountDop = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress1 = Environment.dop;
  const contract = dopContract(tokenAddress1, web3);
  const balacefAccountDop = useCallback(
    async () => {
      try {
        const buy = await contract.methods.balanceOf(account).call();
        return buy/10**18;
      } catch (error) {
        throw error;
      }
    },
    [contract, account]
  );
  return { balacefAccountDop:balacefAccountDop };
};
export default BalacefAccountDop;