import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getGemsStakingContract } from "../../utils/contractHelpers";

const UnStakeGems = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.GemsStaking;
  const contract = getGemsStakingContract(tokenAddress, web3);
  const unstakeGems = useCallback(
    async (account, index) => {
      // let nd = 1000000000000000000000;
      // nd = web3.utils.toWei(nd.toString(), "ether");
      try {
        const gas = await contract.methods
          .unstake([index])
          .estimateGas({ from: account });

        const details = await contract.methods.unstake([index]).send({
          from: account,
          gas,
        });
        return details;
      } catch (error) {
        throw error;
      }
    },
    [contract]
  );
  return { unstakeGems: unstakeGems };
};
export default UnStakeGems;
