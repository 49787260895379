import React, { useEffect, useState } from "react";
import "./claim.scss";
import Navbar from "../landing/header/Navbar";
import { Accordion } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import Loader from "../../hooks/loader";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Claim1 from "../../hooks/chinaFunction/claim";
import ClaimAll from "../../hooks/chinaFunction/claimAll";
import { array } from "i/lib/util";
import { toast } from "react-toastify";
import EthPrice from "../../hooks/chinaFunction/ethPrice";
import { intersection } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
const ClaimNft = () => {
  const [ethPricevalue, setEthPrice] = useState(0);
  const { ethPrice } = EthPrice();
  let now = Date.now();
  const role = localStorage?.getItem("role_ins");
  const { claim1 } = Claim1();
  const { claimAll } = ClaimAll();
  const [pageCount, setPageCount] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [totalArry, settotalArry] = useState([]);
  const { account } = useWeb3React();
  const [mainLoader, setMainLoader] = useState(false);
  const [mainLoader1, setMainLoader1] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [check, setCheck] = useState(false);

  // const handlePageClick = (e) => {
  //   setMainLoader1(true);
  //   const selectedPage = e.selected;
  //   let skip = "";
  //   if (e.selected > 0) {
  //     skip = JSON.stringify(e.selected * limit);
  //     setPage(selectedPage);
  //   } else {
  //     skip = JSON.stringify(0);
  //     setPage(0);
  //   }
  //   var data = JSON.stringify({
  //     query: `query MyQuery {
  //       investments(
  //         where: {by: "${account}"}
  //         orderBy: blockTimestamp
  //         orderDirection: desc
  //         first:10
  //         skip: ${skip}
  //       ) {
  //         blockTimestamp
  //         amountInvested
  //         by
  //         code
  //         dopPurchased
  //         id
  //         price
  //         transactionHash
  //         tokenSelected
  //         round
  //       }
  //     }`,
  //   });
  //   var config = {
  //     method: "post",
  //     url: "https://api.thegraph.com/subgraphs/name/saifdevblochain/dop",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       setPageCount(totalLength / limit);
  //       settotalArry(response?.data?.data?.investments);
  //       ClaimAll111();
  //       setMainLoader1(false);
  //     })
  //     .catch(function (error) {
  //       setMainLoader1(false);
  //     });
  // };
  // const nftMainArryHandler1 = async () => {
  //   setMainLoader1(true);
  //   var data = JSON.stringify({
  //     query: `query MyQuery {
  //       investments(
  //         where: {by: "${account}"}
  //         orderBy: blockTimestamp
  //         orderDirection: desc
  //       ) { blockTimestamp
  //         amountInvested
  //         by
  //         code
  //         dopPurchased
  //         id
  //         price
  //         transactionHash
  //         tokenSelected
  //         round
  //       }
  //     }`,
  //     variables: {},
  //   });
  //   var config = {
  //     method: "post",
  //     url: "https://api.thegraph.com/subgraphs/name/saifdevblochain/dop",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };
  //   await axios(config)
  //     .then(async function (response) {
  //       console.log(response,'response?.data?.data?.investments');
  //       setPageCount(response?.data?.data?.investments?.length / limit);

  //       setTotalLength(response?.data?.data?.investments?.length);
  //       setMainLoader1(false);
  //     })
  //     .catch(function (error) {
  //       setMainLoader1(false);
  //       console.log(error, "error");
  //     });
  // };
  const code = localStorage?.getItem("accessCode");
  const email = localStorage?.getItem('email')
  // useEffect(()=>{
  //   // console.log('usefffef',email,code);
  //   if(!email||!code){
  //   history.push('/')
  //   }
  // },[])
  const nftMainArryHandler2 = async () => {
    setMainLoader1(true);
    var data = JSON.stringify({
      query: `query MyQuery {
        investments(where: {by: "${account}"}
    orderBy: blockTimestamp
    orderDirection: desc
        ) {
          amountInvested
          by
          id
          nftAmounts
          round
          tokenSelected
          blockTimestamp
          roundPrice
        }
      }`,
      variables: {},
    });
    var config = {
      method: "post",
      url: "https://api.thegraph.com/subgraphs/name/saifdevblochain/buywithnft",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(async function (response) {
        // console.log(response, 'response?.data?.data?.investments mainn1');
        // settotalArry(response?.data?.data?.investments);
        // setMainLoader1(false);
        // ClaimAll111();
        setMainLoader1(false);
        settotalArry(response?.data?.data?.investments);
        // instittion(response?.data?.data?.investments)
      })
      .catch(function (error) {
        setMainLoader1(false);
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (account) {
        nftMainArryHandler2();

    } else {
      settotalArry(null)
    }
  }, [account, ethPricevalue]);

  const [show, setShow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handler121 = async (e) => {
    setMainLoader(true);
    let z = e.currentIndex;
    try {
      let t = await claim1(z);
      if (t) {
        setMainLoader(false);
        window.location.reload();
      }
    } catch (err) {
      console.log(err, "err");
      setMainLoader(false);
      toast.error("Transaction reverted");
    }
  };
  const ClaimAll11 = async (e) => {
    let dummy = [];
    totalArry.map((e, i) => {
      if (now > e?.ReleaseTime * 1000 && e?.isClaimed == false) {
        // console.log(e, "e");
        dummy.push(e.currentIndex);
      }
    });
    if (dummy?.length > 0) {
      setMainLoader(true);
      try {
        let b = await claimAll(dummy);
        if (b) {
          setMainLoader(false);
          window.location.reload();
        }
      } catch (err) {
        setMainLoader(false);
        console.log(err, "err");
        toast.error("Transaction reverted");
      }
    }
  };
  const ClaimAll111 = async (e) => {
    let dummy = [];
    totalArry.map((e, i) => {
      if (now > e?.ReleaseTime * 1000 && e?.isClaimed == false) {
        dummy.push(e.currentIndex);
      }
    });
    if (dummy?.length > 0) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };



  // const firstTimeData = async () => {
  //   try {
  //     let a = await ethPrice();
  //     setEthPrice(a)
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };


  const [copy, setCopy] = useState(false);
  const textCopiedFun2 = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  return (
    <>
      {mainLoader1 && (
        <Loader />
      )}
      {mainLoader && (
        <Loader text={"Please wait, your transaction is in process"} />
      )}
      <Navbar show={show} setShow={setShow} />
      <section className="claim">
        <div className="custom-container">
          <div className="claim-heading">
            <h6>Claim DOP</h6>

          </div>
          <div className="bottom-table">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Purchased on</th>
                    <th>PURCHASE PRICE</th>
                    <th>Amount Spent</th>
                    <th>NFT</th>
                    <th>Available on</th>

                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>
                  {totalArry?.map((e) => {
                     let b=e?.nftAmounts?.reduce((a, b) => parseInt(a )+ parseInt(b), 0)
                    let totalPric = 0
                    let a = parseInt(e?.blockTimestamp);
                    let cc = new Date(a);
                    const StackIntialDate = moment(cc * 1000).format(
                      "DD-MM-YYYY H:mm"
                    );
                    let puchased = 0;
                    
                    if (e?.tokenSelected === "ETH") {
                      puchased = (parseInt(e?.amountInvested) / 10 ** 18).toFixed(
                        4
                      );
                      totalPric = (puchased * ethPricevalue / ((e?.price) / 10 ** 18)).toFixed(4)
                    } else {
                      puchased = (parseInt(e?.amountInvested) / 10 ** 6).toFixed(4);
                      totalPric = (puchased / ((e?.price) / 10 ** 18)).toFixed(4)
                    }
               
                   
                    return (
                      <>
                        <tr>
                          <td>{StackIntialDate}</td>
                          <td>$  {((e?.roundPrice) / 10 ** 18).toFixed(4)}{" "}/DOP</td>
                          <td>
                            {puchased} {e?.tokenSelected}
                          </td>
                          <td>
                            {b}
                          </td>
                          <td>Q1,2024</td>


                          {now > e?.ReleaseTime * 1000 ? (
                            <>
                              {e.isClaimed == false ? (
                                <td>
                                  <button
                                    className="btn-claim"
                                    onClick={() => {
                                      handler121(e);
                                    }}
                                  >
                                    Claim
                                  </button>
                                </td>
                              ) : (
                                <td>
                                  <button className="btn-claim claimed">
                                    Claimed
                                  </button>
                                </td>
                              )}
                            </>
                          ) : (
                            <td>
                              <button className="btn-claim disable">
                                Claim
                              </button>
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              {/* <div className="footer-content">
                <div className="left-f">
                  <h6 className="paginationtext">
                    SHOWING {page + 1}-{limit} OF {total}
                  </h6>
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    {pageCount >= 1 ? (
                      <div className="text-center">
                        <ReactPaginate
                          previousLabel={<img src="\assets\arrow1.svg" />}
                          nextLabel={<img src="\assets\arrow2.svg" />}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </div>
                    ) : (
                      ""
                    )}
                  </nav>
                </div>
              </div> */}
            </div>
            <div className="accmblview d-none">
              <h6 className="accmblviewhead">
                Purchased on{" "}
              </h6>
              {totalArry?.map((e) => {
                 let bb=e?.nftAmounts?.reduce((a, b) => parseInt(a )+ parseInt(b), 0)
                 console.log(bb,'bb');
                let totalPric = 0
                let a = parseInt(e?.blockTimestamp);
                let cc = new Date(a);
                const StackIntialDate = moment(cc * 1000).format(
                  "DD-MM-YYYY H:mm"
                );
                let puchased = 0;
                if (e?.tokenSelected === "ETH") {
                  puchased = (parseInt(e?.amountInvested) / 10 ** 18).toFixed(
                    4
                  );
                  totalPric = (puchased * ethPricevalue / ((e?.price) / 10 ** 18)).toFixed(4)
                } else {
                  puchased = (parseInt(e?.amountInvested) / 10 ** 6).toFixed(4);
                  totalPric = (puchased / ((e?.price) / 10 ** 18)).toFixed(4)
                }

                return (
                  <>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <div className="accheadermain">
                            <p className="acctext">{StackIntialDate}</p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="acctexts">
                            <h6 className="textleft">PURCHASE PRICE</h6>
                            <p className="textright">$ {(e?.roundPrice / 10 ** 18).toFixed(4)}{" "}/DOP</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Amount Spent</h6>
                            <p className="textright"> {puchased} {e?.tokenSelected}</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">NFT</h6>
                            <p className="textright">{bb}</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Available on</h6>
                            <p className="textright">Q1,2024</p>
                          </div>
                          <div className="acctexts">
                            <h6 className="textleft">Action</h6>
                            <button className="btn-claim disable">Claim</button>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                );
              })}

              {/*          
              <div className="footer-content">
                <div className="left-f">
                  <h6 className="paginationtext">
                    SHOWING {page + 1}-{limit} OF {total}
                  </h6>
                </div>
                <div className="right-f">
                  <nav aria-label="Page navigation example">
                    {pageCount >= 1 ? (
                      <div className="text-center">
                        <ReactPaginate
                          previousLabel={<img src="\assets\arrow1.svg" />}
                          nextLabel={<img src="\assets\arrow2.svg" />}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          activeClassName="active"
                          forcePage={page}
                        ></ReactPaginate>
                      </div>
                    ) : (
                      ""
                    )}
                  </nav>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClaimNft;
