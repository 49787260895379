import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { dopContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const AllowanceDop1 = () => {

  // const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.instituson;
  const tokenAddress1 = Environment.dop
  const contract = dopContract(tokenAddress1, web3);


  const allowanceDop1 = useCallback(
    async () => {
        // console.log(tokenAddress,account,'noor');
      try {
        const buy = await contract.methods.allowance(account,tokenAddress).call()
        // console.log('sdsdss' ,  buy)
        return buy/10**18;
      } catch (error) {
        throw error;
      }
    },
    [contract, account,web3]
  );
  return { allowanceDop1: allowanceDop1 };
};
export default AllowanceDop1;