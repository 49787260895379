import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const UniPrice = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);


  const uniPrice = useCallback(
    async () => {
 
      try {
        const buy = await contract.methods.getLatestPrice(Environment.unisape).call()
        // console.log(buy[0]/10**8,"buy");
        return buy[0]/10**8;
      } catch (error) {
        throw error;
      }
    },
    [contract, account,web3]
  );
  return { uniPrice: uniPrice };
};
export default UniPrice;