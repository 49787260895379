import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const ClaimAll = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);


  const claimAll = useCallback(
    async (amount) => {
      try {
        const gas = await contract.methods.claimAllTomi(amount).estimateGas({
            from:account });
        const details = await contract.methods.claimAllTomi(amount).send({
          from: account,
          gas,
        });
        return details;
        // console.log('sdsdss' ,  buy)
        // return details;
      } catch (error) {
        throw error;
      }
    },
    [contract, account]
  );
  return { claimAll: claimAll };
};
export default ClaimAll;