import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { chinaContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const  DiscountedPrice= () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.chinaTomi;
  const contract = chinaContract(tokenAddress, web3);


  const discountedPrice = useCallback(
    async (round,address) => {
 
      try {
        const buy = await contract.methods.allowedTokens(round,address).call()
        // console.log(buy,'buy');
        return buy[1]/10**18;
      } catch (error) {
        throw error;
      }
    },
    [contract, account,web3]
  );
  return { discountedPrice: discountedPrice };
};
export default DiscountedPrice;