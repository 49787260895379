import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getGemsStakingContract } from "../../utils/contractHelpers";

const StakeGems = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.GemsStaking;
  const contract = getGemsStakingContract(tokenAddress, web3);
  const stakeGems = useCallback(
    async (account, amount) => {
      // let nd = 1000000000000000000000;
      // nd = web3.utils.toWei(nd.toString(), "ether");
      try {
        // let amnt = amount * 10 ** 18;
        let nd = web3.utils.toWei(amount.toString(), "ether");

        const gas = await contract.methods
          .stake(nd)
          .estimateGas({ from: account });
        const details = await contract.methods.stake(nd?.toString()).send({
          from: account,
          gas,
        });
        return details;
      } catch (error) {
        throw error;
      }
    },
    [contract]
  );
  return { stakeGems: stakeGems };
};
export default StakeGems;
