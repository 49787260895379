import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getGemsStakingContract } from "../../utils/contractHelpers";

export const GetGemsStaked = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.GemsStaking;
  const contract = getGemsStakingContract(tokenAddress, web3);
  const getGemsStaked = useCallback(
    async (account) => {
      const index = await contract.methods
        .stakeIndex(account?.toString())
        .call();
      if (index) {
        let bal = 0;
        for (let i = 0; i < index; i++) {
          const stakedAmount = await contract.methods
            .stakes(account?.toString(), i)
            .call();
          bal = parseInt(stakedAmount?.amount?.toString()) + bal;
        }

        bal = parseInt(bal / 10 ** 18);
        return bal;
      } else {
        return 0;
      }
    },
    [contract]
  );

  return { getGemsStaked: getGemsStaked };
};

export default GetGemsStaked;
